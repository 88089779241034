import React from 'react'
import { OrganizationJsonLd } from 'next-seo'
import Layout from '../../src/components/layouts'
import defaultSEO from '../../src/common/constants/nextSEO'
import CustomJsonLd from '../../src/components/shared/CustomJsonLd'
import { APP_BASE_PATH } from '../../src/common/constants'
import { data } from '../../src/components/pages/Home/consts'
import { jsonLdData } from '../../src/common/constants/jsonLd'
import HomePage from '../../src/components/pages/Home/variant-8'
import WithSeoOverrides from 'src/components/shared/WithSeoOverride'
import CfosRepository from 'src/repositories/CfosRepository'
import logger from 'src/common/logger'
import TrustMarkersCombinationRepository from 'src/repositories/TrustMarkersCombinationRepository'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { featureToggles } from 'src/common/constants/featureToggles'

export async function getServerSideProps(context) {
  let cfosData = []
  let trustMarkersCombination

  try {
    const resp = await CfosRepository.getInstance().getModels(
      {},
      context?.preview
    )
    const list =
      resp.find((item) => item.title === 'cfos-mapping')?.values?.data || []
    cfosData = list.reduce((acc, item) => {
      return {
        ...acc,
        [item.value]: [
          item.cfosMinStrategy.monthly,
          item.cfosMinStrategy.total,
          item.option2.monthly,
          item.option2.total,
          item.cfosMinStrategy.timeToPay,
          item.option2.timeToPay,
        ],
      }
    }, {})

    const trustMarkersResponse =
      await TrustMarkersCombinationRepository.getInstance().getModels(
        {},
        context?.preview
      )
    trustMarkersCombination = trustMarkersResponse.find(
      (item) => item.id.toLowerCase() === 'home-variant-8'
    )
    if (trustMarkersCombination) {
      trustMarkersCombination = {
        ...trustMarkersCombination,
        trustMarker: trustMarkersCombination.trustMarker.map(
          ({ metadata, sys, fields }) => ({
            metadata,
            sys,
            fields: {
              ...fields,
              color: '#33b083',
            },
          })
        ),
      }
    }
  } catch (error) {
    logger.error('Home page: Ups something bad happens', {
      error,
    })
  }

  return {
    props: {
      homeVariation: 'variant-8',
      cfosData,
      trustMarkersCombination: trustMarkersCombination || null,
    },
  }
}

// Control page
const Variant8 = (pageProps) => {
  const { homeVariation, cfosData, trustMarkersCombination } = pageProps

  const { getExperimentVariation } = useFeatureFlags()
  const cfosDisclousuresVariation = getExperimentVariation(
    experimentSectionByVariation[featureToggles.CFOS_DISCLOUSURES]
  )
  const isCfosDisclousuresVariationActive =
    cfosDisclousuresVariation === 'control'

  const JSON_LD_DATA = {
    type: 'Organization',
    id: `${APP_BASE_PATH()}/#organization`,
    logo: `${APP_BASE_PATH()}/next-assets/fdr-logo.webp`,
    legalName: 'Freedom Debt Relief LLC',
    name: 'Freedom Debt Relief',
    address: {
      streetAddress: '1875 S Grant St #400',
      addressLocality: 'San Mateo',
      addressRegion: 'CA',
      postalCode: '94402',
      addressCountry: 'US',
    },
    sameAs: [
      'https://twitter.com/freedomdebt',
      'https://www.facebook.com/freedomdebtrelief/',
      'https://www.linkedin.com/company/freedom-debt-relief/',
      'https://www.youtube.com/freedomdebtrelief',
      'https://www.instagram.com/freedomdebtrelief/',
      'https://www.google.com/maps?cid=1138888979161863326',
    ],
    contactPoints: [
      {
        type: 'ContactPoint',
        telephone: '+18009100065',
        contactType: 'customer service',
      },
    ],
    url: APP_BASE_PATH(),
  }

  return (
    <>
      <WithSeoOverrides {...defaultSEO} />
      <OrganizationJsonLd {...JSON_LD_DATA} />
      <CustomJsonLd
        id={'webPage'}
        data={jsonLdData(defaultSEO?.title, defaultSEO?.description, '/')}
      />
      <Layout isBrandPage={true} isHome={true} homeVariation={homeVariation}>
        <HomePage
          data={{
            ...data,
            showBarChart: true,
            cfosData,
            isCfosDisclousuresVariationActive,
            trustMarkersCombination,
          }}
        />
      </Layout>
    </>
  )
}

export default Variant8
